<template>
  <div class="flex items-center">
    {{this.params.data.position}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererPosition'
};
</script>
