<template>
  <div class="flex items-center">
    <vs-avatar :src="photoUrl" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)"/>
    <router-link :to="url" class="text-inherit hover:text-primary" @click.stop.prevent>{{
        this.service_data.name
      }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererService',
  data() {
    return {
      service_data: {
        images: {

        }
      },
    };
  },
  computed: {
    url() {
      return `/a/services/${this.params.data.service_id}`;
    },
    photoUrl() {
      return this.service_data.images.shop||'';
    },
  },
  created() {
    this.service_data.id = this.params.data.service_id;
    this.service_data.name = this.params.data.service_id;

    this.$http.get(`services/${this.service_data.id}`)
      .then(response => {

        if (response.data.data) {
          this.service_data = response.data.data;
        }

      });
  },
};
</script>
