<!-- =========================================================================================
  File Name: ProductList.vue
  Description: Product List page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>

  <div id="page-product-list">

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown class="cursor-pointer" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }} - {{
                  productsData.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : productsData.length
                }} of {{ productsTotal }}</span>
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          </vs-dropdown>

          <vs-button @click="addNewData" class="ml-5" color="danger" type="gradient">
            Create an Entry
          </vs-button>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <vs-input @input="updateSearchQuery" class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                  placeholder="Search..." v-model="searchQuery"/>
        <vs-button @click="gridApi.exportDataAsCsv()" class="mb-4 md:mb-0">Export as CSV</vs-button>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="productsData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" v-model="currentPage"/>

    </div>
  </div>

</template>

<script>
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

// Store Module
// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue';
import CellRendererPrices from './cell-renderer/CellRendererPrices';
import CellRendererActions from './cell-renderer/CellRendererActions.vue';
import CellRendererPosition from './cell-renderer/CellRendererPosition';
import CellRendererService from './cell-renderer/CellRendererService';

export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererPrices,
    CellRendererActions,
    CellRendererPosition,
    CellRendererService,
  },
  data() {
    return {

      productsData: [],
      productsTotal: 0,

      searchQuery: '',

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            let value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },
      columnDefs: [
        {
          headerName: 'Name',
          field: 'name',
          filter: true,
          width: 400,
          cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: 'Service',
          field: 'service_id',
          filter: true,
          cellRendererFramework: 'CellRendererService',
        },
        {
          headerName: 'Type',
          field: 'type',
          filter: true,
        },
        {
          headerName: 'Price/Children',
          field: 'price',
          filter: false,
          sortable: false,
          cellRendererFramework: 'CellRendererPrices',
        },
        {
          headerName: 'Status',
          field: 'status',
          filter: true,
        },
        {
          headerName: 'Position',
          field: 'position',
          cellRendererFramework: 'CellRendererPosition'
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this)
          }
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererActions,
      },
      totalPages: 0,
    };
  },
  watch: {},
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    addNewData() {
      this.$router.push('/a/products/create')
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = {type: 'equals', filter: val};
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    loadProductBuffer(page = 0) {

      if (page === 0) {
        page = this.currentPage;
      }

      const partnerId = this.activeUserInfo.partner_id;

      this.$http.get(`billing/products?amount=${this.paginationPageSize}&page=${page}&partner_id=${partnerId}`)
        .then(response => {

          if (response.data.data) {
            this.productsData = response.data.data;
          }

          this.gridApi.sizeColumnsToFit();
        })
        .catch(exception => {

          let error = 'An unknown error occurred while loading the product list';
          if (exception.response) {
            error = exception.response.data.error.description;
          }

          return this.$vs.notify({
            title: 'Failed to load product list',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
  created() {

    const partnerId = this.activeUserInfo.partner_id;

    this.$http.get(`billing/products/counts?amount=${this.paginationPageSize}&partner_id=${partnerId}`)
      .then(response => {
        if (response.data.data) {

          const data = response.data.data;

          if (data.pages) {
            this.totalPages = data.pages;
            this.productsTotal = data.total;
          }

        }
      })
      .catch(exception => {

      });


  },
};

</script>

<style lang="scss">
#page-product-list {
  .product-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
